import Box from "@material-ui/core/Box";

export default function Pricelist() {
	
	const today = new Date();
	const timestamp = today.getTime();
	
	return <>
		<Box textAlign="center">
			<img src={`https://static.reshopper.com/retail/popupPrislisteV8.webp?v=${timestamp}`} style={{width: "100%", maxWidth: "1054px", height: "auto"}} /> 
		</Box>
	</>
}
